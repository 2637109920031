import React from "react"

import { useLocation } from "@reach/router"
import { Link } from "../../services/routes" // "gatsby";
import routes from "../../services/routes"

const fa = require("../site/static/vendors/fontawesome/fontawesome.module.css") as any;
const sidebar = require("./sidebar.module.css") as any;

export const SIDEBAR_UNCOLLAPSED_WIDTH = 160;
export const SIDEBAR_COLLAPSED_WIDTH = 50;

const logo = require("../../images/logo.svg") as string;
const s = require("../../images/s.svg") as string;

// use id="link-beta" to get orange highlight -- better yet, convert to a class (see sidebar.css)
const NavLink = ({name, icon, href, collapsed}) => {
	const location = useLocation();
	const pathname = location.pathname;
	const active = (pathname===href) || (pathname===routes.account.index && href===routes.account.boards);
	return (
		<li className={sidebar.linkWrapper}>
			<Link className={active ? `${sidebar.link} ${sidebar.active}` : sidebar.link} to={href} state={{sidebarCollapsed:collapsed}}>
				<i className={`${sidebar.sidebarIcon} ${fa.fa} ${icon}`} aria-hidden="true"></i>
				{ !collapsed &&
					<span className={sidebar.description}>{name}</span>
				}
			</Link>
		</li>
	);
};

function sidebarStyle(collapsed) {
	const width = !collapsed ? SIDEBAR_UNCOLLAPSED_WIDTH : SIDEBAR_COLLAPSED_WIDTH;
	return {
		width,
		minWidth: width,
		maxWidth: width,
	};
}

export default ({collapsed, setCollapsed}) => {
	const toggleCollapsed = (event) => {
		event.preventDefault();
		setCollapsed(!collapsed);
	}
	return (
	<nav className={sidebar.sidebar} style={sidebarStyle(collapsed)}>
		<div>
			<Link to={routes.index}>
				{ !collapsed ?
					<img style={{height:"20px", display:"block", margin:"auto", marginTop:"10px", marginBottom:"10px"}} src={logo} />
					:
					<img style={{height:"20px", display:"block", margin:"auto", marginTop:"10px", marginBottom:"10px"}} src={s} />
				}
			</Link>
		</div>
		<ul className={sidebar.links}>
			<NavLink name="Boards" icon={fa.faPencilSquareO} href={routes.account.boards} collapsed={collapsed} />
			{/*<NavLink name="Contacts" icon={fa.faUsers} href={routes.account.contacts} collapsed={collapsed} />*/}
			<NavLink name="Feedback" icon={fa.faLightbulbO} href={routes.account.feedback} collapsed={collapsed} />
			<NavLink name="Settings" icon={fa.faCogs} href={routes.account.settings} collapsed={collapsed} />
			<NavLink name="Logout" icon={fa.faSignOut} href={routes.logout} collapsed={collapsed} />
		</ul>
		<div className={sidebar.sidebarCollapse} style={sidebarStyle(collapsed)}>
			<a className={sidebar.collapse} onClick={toggleCollapsed} style={{cursor:"pointer"}}>
				<i className={`${fa.fa} ${collapsed ? fa.faArrowRight : fa.faArrowLeft} ${sidebar.collapseIcon}`} aria-hidden="true"></i>
				{ !collapsed &&
					<span className={sidebar.description}>Collapse Sidebar</span>
				}
			</a>
		</div>
	</nav>
	);
};