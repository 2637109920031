import React, { useEffect, useState } from "react"

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Loader from "react-loader-spinner"

import { getConfirmedUserOtherwiseNavigate } from "../../services/auth"
import { navigate, Link } from "../../services/routes" // "gatsby"
import { BoardInfo } from "../../services/api/boards"
import routes from "../../services/routes"

const fa = require("../site/static/vendors/fontawesome/fontawesome.module.css") as any;

const Board = ({board, showModal}) => {
	const remove = (event) => {
		event.preventDefault();
		showModal(board);
	}
	return (
		<tr>
			<td>
				<Link to={routes.board(board.urlId)}>{board.name}</Link> &nbsp;
			</td>
			<td>
				{new Date(board.updatedAt).toDateString()}
			</td>
			<td>
				<a onClick={remove} style={{cursor:"pointer"}}>
					<i className={`${fa.fa} ${fa.faTrash}`} aria-hidden="true"></i>
				</a>
			</td>
		</tr>
	);
}

const boardFilter = (query) => {
	const queryLower = query.toLowerCase();
	return (board) => (
		board.name.toLowerCase().includes(queryLower)
	);
}

const BoardList = ({boards, showModal}) => {
	const [state, setState] = useState({query:""});
	const changeQuery = (event) => {
		setState({...state, query: event.target.value});
	}
	const newBoard = async () =>  {
		const user = getConfirmedUserOtherwiseNavigate();
		if (user) {
			const board = await user.addBoard();
			if (board) {
				navigate(routes.board(board.urlId));
			}
		}
	}
	return (
		<>
			<Button onClick={newBoard} variant="success" >New Board</Button>
			<br/>
			<br/>
			<Table>
				<thead>
					<tr>
						<th>
							<input onChange={changeQuery} placeholder="search boards" />
						</th>
						<th></th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{
						boards
							.filter(boardFilter(state.query))
							.sort((b1: BoardInfo, b2: BoardInfo) => (b2.updatedAt < b1.updatedAt ? -1 : 1))
							.map(board => (
							<Board key={board.urlId} board={board} showModal={showModal} />
						))
					}
				</tbody>
			</Table>
		</>
	);
}

const DeleteBoardModal = ({board, onHide, removeBoard}) => {
	const remove = () => {
		removeBoard(board);
	}
	return (
		<Modal show={board!==null} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>Delete Board</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				Are you sure you wish to remove "{board.name}"?
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={onHide} >Cancel</Button>
				<Button variant="danger" onClick={remove} >Delete</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default () => {
	const [state, setState] = useState({
		loading: true,
		boards: [],
		boardsRequested: false,
		modalBoard: null,
	});
	useEffect(() => {
		if (!state.boardsRequested) {
			setState({...state, boardsRequested: true});
			(async () => {
				const user = getConfirmedUserOtherwiseNavigate();
				const boards = user ? await user.getBoards() : [];
				setState({...state, boardsRequested: true, boards, loading: false });
			})();
		}
	});
	const showModal = (board) => {
		setState({...state, modalBoard: board});
	}
	const closeModal = () => {
		setState({...state, modalBoard: null});
	}
	const removeBoard = async (board) => {
		const user = getConfirmedUserOtherwiseNavigate();
		if (user) {
			const success = await user.removeBoard(board);
			if (success) {
				const boards = await user.getBoards();
				setState({...state, boards, modalBoard: null});
			}
		}
	}
	return (
		<>
			{ state.loading &&
				// @ts-ignore
				<Loader type="Rings" color={"rgb(59,172,240)"} height={50} />
			}
			{ !state.loading &&
				<BoardList boards={state.boards} showModal={showModal} />
			}
			{ state.modalBoard &&
				<DeleteBoardModal board={state.modalBoard} onHide={closeModal} removeBoard={removeBoard} />
			}
		</>
	);
};
